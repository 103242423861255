<template>
  <div>
    <home-banner></home-banner>
    <div class="container">

      <div class="home-section">
        <h1 class="text-content">
          Valentin Shamsnejad
        </h1>

        <div class="text-content tagline">
          Engineer · Author · Comedian
        </div>

        <div class="bubbles">
          <div class="text-content bubble shadowed right-bubble">
            Hey
          </div>

          <div class="text-content bubble shadowed right-bubble">
            Why are you called "Caillou" exactly ?
          </div>
          
          <div class="text-content bubble shadowed">
            Short story : It's because of the webcomic I wrote, <router-link to="/webcomic" class="link" >check it out</router-link>
          </div>

          <div class="text-content bubble shadowed">
            Long story : It's a long story
          </div>

          <div class="text-content bubble shadowed right-bubble">
            Ok, but why <i>"caillou.ninja"</i> for your website, then ? There are no ninjas in your webcomic
          </div>

          <div class="text-content bubble shadowed">
            I just thought it was funny in 2015 when I heard about the <i>".ninja"</i> domain name
          </div>

          <div class="text-content bubble shadowed">
            I regret nothing 😎
          </div>

          <div class="text-content bubble shadowed right-bubble">
            What's your thing then, apart from rocks and webcomics ?
          </div>

          <div class="text-content bubble shadowed">
            I'm so glad you asked, because I've made a whole website about it, what an incredible coincidence !
          </div>

          <div class="text-content bubble shadowed">
            Choose your category 👇
          </div>
        </div>
      </div>

      <div class="buttons-section">
        <router-link :to="{ name: 'Engineer' }">
          <div class="engineer image-button shadowed">
            <h2>Software Engineer</h2>
          </div>
        </router-link>

        <router-link :to="{ name: 'AuthorAndComedian' }">
          <div class="comedian image-button shadowed">
            <h2>Author & Comedian</h2>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import HomeBanner from '@/components/home/HomeBanner'

export default {
  name: 'home',
  components: { HomeBanner }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/styles/sizes.scss';
@import '@/styles/colors.scss';

h1 {
  font-size: 50px;
  text-align: center;
}

h2 {
  font-size: 40px;
  margin-bottom: 30px;
}

.tagline {
  font-size: 25px;
  margin: 15px 0;
  text-align: center;
}

.home-section {
  padding-bottom: 50px;
}

.buttons-section {
  padding-bottom: 1px; //to get the margin bottom of the last button inside the page
}

.bubbles {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
  text-align: left;
}

.bubble {
  background-color: white;
  padding: 10px 15px;
  margin-top: 15px;
  margin-right: 50px;
  border-radius: 20px;
  border-top-left-radius: 0;
  width: fit-content;
  align-self: start;
}

.right-bubble {
  margin-right: 0;
  margin-left: 50px;
  border-top-left-radius: 20px;
  border-top-right-radius: 0;
  align-self: end;
  background-color: $secondary-lighter;
}

.image-button {
  min-height: 300px;
  padding: 30px;
  margin-bottom: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: $white;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  transition: opacity 0.2s ease-in-out;

  &:hover, &:active {
    opacity: 0.7;
  }

  h2 {
    margin: 0;
  }
}

.engineer {
  background-image: url('@/../public/home-panels/matrix.jpeg');
}
.comedian {
  background-image: url('@/../public/home-panels/microphone.jpeg');
}

</style>
