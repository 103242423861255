<template>
  <div class="menus-container">
    <left-menu  :isOpen="isOpen"
                @close="close">
    </left-menu>
    <div  class="top-menu shadowed transition"
          :data-hidden="isHidden">
      <div  class="menu-item clickable"
            @click="toggleOpen">
        <div  id="nav-icon1"
              :data-open="isOpen">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <router-link  to="/"
                    class="menu-item clickable">
        <img  class="avatar"
              src="/caillou_avatar.svg" />
      </router-link>
    </div>
  </div>
</template>

<script>
import LeftMenu from '@/components/layout/LeftMenu'
export default {
  name: 'top-menu',
  components: { LeftMenu },
  data () {
    return {
      isOpen: false,
      isHidden: false,
      minScrollToHide: 40,
      
    }
  },
  methods: {
    toggleOpen () {
      this.isOpen = !this.isOpen
    },
    close () {
      this.isOpen = false
    }
  },
  mounted () {
    // Bind event to hide/show top bar on scroll
    var prevScrollpos = window.scrollY
    window.onscroll = () => {
      var currentScrollPos = window.scrollY

      if (currentScrollPos < this.minScrollToHide) {
        this.isHidden = false
      } else if (prevScrollpos > currentScrollPos) {
        this.isHidden = false
      } else {
        this.isHidden = true
      }
      prevScrollpos = currentScrollPos
    }
  },
  watch: {
    isOpen (newVal, oldVal) {
      const body = document.getElementsByTagName('body')[0]
      
      if (newVal) {
        body.classList.add('no-mobile-scroll')
      } else {
        body.classList.remove('no-mobile-scroll')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/sizes.scss';
@import '@/styles/colors.scss';
.top-menu {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: $primary;
  color: $primary-lighter;
  height: $top-bar-height;
  display: flex;
  align-items: center;
  z-index: 20;
}
@media all and (max-width: $max-tablet) {
  .top-menu[data-hidden] {
    transform: translateY(-45px);
  }
}
.menu-item {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.avatar {
  height: 30px;
}

/* Animated Menu Icon */

#nav-icon1 {
  width: 20px;
  height: 15px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
}

#nav-icon1 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: $almost-white;
  border-radius: 3px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
  top: 0px;
}

#nav-icon1 span:nth-child(2) {
  top: 6px;
}

#nav-icon1 span:nth-child(3) {
  top: 12px;
}

#nav-icon1[data-open] span:nth-child(1) {
  top: 6px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#nav-icon1[data-open] span:nth-child(2) {
  opacity: 0;
  left: -30px;
}

#nav-icon1[data-open] span:nth-child(3) {
  top: 6px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
</style>
