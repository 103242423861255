<template>
  <div id="app">
    <top-menu>
    </top-menu>
    <div class="content">
      <router-view/>
    </div>
  </div>
</template>

<script>
import TopMenu from '@/components/layout/TopMenu'
export default {
  name: 'App',
  components: { TopMenu }
}
</script>

<style lang="scss">
@import '@/styles/sizes.scss';
@import '@/styles/colors.scss';
@import '@/styles/fonts.scss';

#app {
  background-color: $primary-lighter;
  min-height: 100%;
}
#app {
  color: $primary-dark;
}
#app, input {
  font-family: $textPrimary, 'Avenir', Helvetica, Arial, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

* {
  box-sizing: border-box;
}
body {
  margin: 0;
}
html, body {
  width: 100%;
  height: 100%
}
.content {
  padding-top: $top-bar-height;
  min-height: 100vh;
}
.container {
  text-align: center;
  max-width: 900px;
  margin: 0 auto;
}
.text-content {
  padding: 0 15px;
}

h1, h2 {
  font-weight: normal;
  padding: 15px 0;
  margin: 0;
  font-family: $titlePrimary;
}
a {
  text-decoration: none;
  color: $primary-dark;
}
a.link {
  text-decoration: underline;
  color: $link-blue;
}
input {
  border: none;
  padding: 5px;
  outline: none;
  box-sizing:border-box;
}
.shadowed {
  box-shadow: 0 2px 5px $dark-transparent;
}
.clickable {
  cursor: pointer;
  transition: background-color .1s ease-in-out;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.clickable:active {
  background-color: $dark-transparent;
}
.transition {
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.hero-container {
  background-color: black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 150px 0;
  margin-bottom: 30px;
  color: white;
}

.home-button {
  margin-top: 30px;
  padding: 15px 30px;
  border-radius: 5px;
  background-color: $secondary;
  color: $white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  &:hover, &:active {
    opacity: 0.7;
  }
}

@media all and (max-width: $max-mobile) {
  h1 {
    font-size: 25px;
  }
  h2 {
    font-size: 17px;
  }
  
  .no-mobile-scroll {
    overflow-y: hidden;
  }
}

@media all and (min-width: $max-mobile) {
  .clickable:hover {
    background-color: $dark-transparent;
  }
}
</style>
