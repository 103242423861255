<template>
  <div class="home-banner">
    <div class="head-container">
      <img src="/home-banner/ma_tete left.svg"
          class="left-head head" 
          :style="{ transform: `translateX(-${headSplitFactor}px)` }"/>

      <div class="brain-rock"
           :style="{ transform: `translateX(${rockPosX}px)`}">
        <img src="/caillou_avatar.svg" 
             class="rock"
             ref="rock"
             :style="{ transform: `translateY(${-rockPosY}px) rotate(${rockRotation}deg) scaleY(${1-rockDeformation}) scaleX(${1+rockDeformation})`}"/>
        <div class="rock-shadow"
             :style="{ transform: `scale(${shadowScale})`}">
        </div>
      </div>
      
      <img src="/home-banner/ma_tete right.svg"
          class="right-head head"
          :style="{ transform: `translateX(${headSplitFactor}px)` }"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home-banner',
  data () {
    return {
      headSplitFactor: 0,
      rockPosY: 0,
      rockPosX: 0,
      rockRotation: 0,
      shadowScale: 1,
      rockDeformation: 0,
      maxScroll: 200,
      fixedMaxScroll: 200,
      flipStart: 100,
      flipHalfDuration: 100,
      flipMaxHeight: 150,
      flipMaxRotation: -360,
      minFlipShadowScale: 0.8,
      bounceDuration: 75,
      maxBounceDeformation: 0.2,
      flightStarterDuration: 100,
      flightStarterMaxRotation: -10,
      flightStarterPosX: -50,
      flightStarterPosY: 50,
      minFlightShadowScale: 0.9,
      flightduration: 200,
      flightMaxRotation: -30,
      flightLastPosX: window.screen.width / 2
    }
  },
  methods: {
    handleScroll () {
      const scrollAmount = Math.min(window.scrollY, this.maxScroll)
      this.headSplitFactor = this.easeOutQuart(scrollAmount / this.maxScroll) * this.maxScroll

      const bounceStart = this.flipStart - this.bounceDuration
      const bounceHalfway = bounceStart + this.bounceDuration/2
      // There is this.flipstart between bounceHalfway and flipHalfway
      const flipHalfway = this.flipStart + this.flipHalfDuration
      const flipEnd = flipHalfway + this.flipHalfDuration
      const secondBounceHalfway = flipEnd + this.bounceDuration/2
      const flightStart = secondBounceHalfway + this.flightStarterDuration
      const flightEnd = flightStart + this.flightduration

      const scrollSinceFlipStart = window.scrollY - this.flipStart
      const normalizedFlipScroll = scrollSinceFlipStart / this.flipHalfDuration
      const rockHeight = this.$refs.rock ? this.$refs.rock.offsetHeight : 200
      this.flightLastPosX = window.screen.width / 2 + rockHeight
      
      this.rockPosY = 0
      this.rockPosX = 0
      this.rockRotation = 0
      this.shadowScale = 1
      this.rockDeformation = 0
      
      // Before the jump
      if (window.scrollY < bounceStart) {
        //do nothing special

      // First part of the Bounce before jump
      } else if (window.scrollY < bounceHalfway) {
        const scrollSinceBounceStart = window.scrollY - bounceStart
        this.rockDeformation = this.easeOutCubic(scrollSinceBounceStart/this.bounceDuration * 2) * this.maxBounceDeformation
        this.rockPosY = -rockHeight * this.rockDeformation / 4 // 4 instead of 2 because the rock is actually taking only half of its image size

      // Second part of the Bounce before jump
      } else if (window.scrollY < this.flipStart) {
        const scrollSinceHalfBounce = window.scrollY - bounceHalfway
        this.rockDeformation = this.easeOutCubic(1- scrollSinceHalfBounce/this.bounceDuration * 2) * this.maxBounceDeformation
        this.rockPosY = -rockHeight * this.rockDeformation / 4 // 4 instead of 2 because the rock is actually taking only half of its image size

      // First part of the jump
      } else if (window.scrollY < flipHalfway) {
        const normalizedBounceScroll = Math.min(scrollSinceFlipStart/this.bounceDuration, 1)
        this.rockDeformation = (normalizedBounceScroll) * -this.maxBounceDeformation/2 // we go from 0 to -maxBounceDeformation/2
        this.rockPosY = this.easeOutSine(normalizedFlipScroll) * this.flipMaxHeight
        this.rockRotation = this.easeInCubic(normalizedFlipScroll) * this.flipMaxRotation / 2
        this.shadowScale = 1 - this.easeOutSine(normalizedFlipScroll) * (1 - this.minFlipShadowScale)
        
      // Second part of the jump
      } else if (window.scrollY < flipEnd) {
        const normalizedBounceScroll = Math.min((flipEnd - window.scrollY)/this.bounceDuration, 1)
        this.rockDeformation = (normalizedBounceScroll) * -this.maxBounceDeformation/2 // we go from -maxBounceDeformation/2 to 0
        this.rockPosY = this.easeOutSine(2 - normalizedFlipScroll) * this.flipMaxHeight
        this.rockRotation = this.easeInSine(normalizedFlipScroll) * this.flipMaxRotation / 2
        this.shadowScale = this.minFlipShadowScale + this.easeInSine(normalizedFlipScroll - 1) * (1 - this.minFlipShadowScale)
      
      // First part of Bounce post flip
      } else if (window.scrollY < secondBounceHalfway) {
        const scrollSinceBounceStart = window.scrollY - flipEnd
        this.rockDeformation = (scrollSinceBounceStart/this.bounceDuration * 2) * this.maxBounceDeformation
        this.rockPosY = -rockHeight * this.rockDeformation / 4 // 4 instead of 2 because the rock is actually taking only half of its image size
        this.rockRotation = this.flipMaxRotation

      // Flight starter after the jump (mixed with the second part of the bounce)
      } else if (window.scrollY < flightStart) {
        const scrollSinceFlightStarterBegan = window.scrollY - secondBounceHalfway
        const normalizedFlightStarterScroll = scrollSinceFlightStarterBegan/this.flightStarterDuration
        this.rockRotation = this.flipMaxRotation + normalizedFlightStarterScroll * this.flightStarterMaxRotation
        this.rockPosX = this.easeInOutCubic(normalizedFlightStarterScroll) * this.flightStarterPosX
        const halfaywPosY = -rockHeight * this.maxBounceDeformation / 4
        this.rockPosY = halfaywPosY + this.easeInOutCubic(normalizedFlightStarterScroll) * (this.flightStarterPosY - halfaywPosY) // we go from halfaywPosY to flightStarterPosY
        this.shadowScale = 1 - normalizedFlightStarterScroll * (1 - this.minFlightShadowScale)
        this.rockDeformation = ((scrollSinceFlightStarterBegan/this.bounceDuration)*2 - 1)*-this.maxBounceDeformation // we go from +maxBounceDeformation to -maxBounceDeformation
      
      // Actual flight ending animation
      } else if (window.scrollY < flightEnd) {
        const scrollSinceFlightBegan = window.scrollY - flightStart
        const normalizedFlightScroll = scrollSinceFlightBegan/this.flightduration
        this.rockRotation = this.flipMaxRotation + this.flightStarterMaxRotation + normalizedFlightScroll*this.flightMaxRotation
        this.rockPosX = this.flightStarterPosX + this.easeInCubic(normalizedFlightScroll) * this.flightLastPosX
        this.rockPosY = this.flightStarterPosY
        this.shadowScale = this.minFlightShadowScale
        this.rockDeformation = (1-Math.min(scrollSinceFlightBegan/(this.bounceDuration/2), 1))*-this.maxBounceDeformation

      // After the jump
      } else {
        this.rockRotation = this.flipMaxRotation +  this.flightMaxRotation
        this.rockPosY = this.flightStarterPosY
        this.rockPosX = this.flightStarterPosX + this.flightLastPosX
        this.shadowScale = this.minFlightShadowScale
      }
    },
    setMaxScroll () {
      this.maxScroll = Math.max(Math.min((window.innerWidth / 2), this.fixedMaxScroll), 0)
    },
    easeOutQuart(x) {
      return 1 - Math.pow(1 - x, 4);
    },
    easeInCubic(x) {
      return x * x * x;
    },
    easeOutCubic(x) {
      return 1 - Math.pow(1 - x, 3);
    },
    easeOutSine(x) {
      return Math.sin((x * Math.PI) / 2);
    },
    easeInSine(x) {
      return 1 - Math.cos((x * Math.PI) / 2);
    },
    easeInOutCubic(x) {
      return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
    }
  },
  created () {
    this.setMaxScroll()
    this.handleScroll()
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.setMaxScroll)
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.setMaxScroll)
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';
@import '@/styles/sizes.scss';

.home-banner {
  height: 328px; //308 height + 20px of margin
  margin-bottom: 100px;
  background-color: $primary-light;
  background-image: url("@/../public/home-banner/triangles.svg");
  background-position: center top;
  background-repeat: none;
  background-size: cover;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow-x: clip;
  overflow-y: visible;
}

.head-container {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
  justify-content: center;
  max-width: calc(100vw);
  position: relative;
}

.head {
  transition: transform 0.1s;
  width: 50%;
  z-index: 2;
  // display: none;
}
.left-head {
  translate: 0.5px 0;
}

.right-head {
  translate: -0.5px 0;
}

.brain-rock {
  top: 200px;
  position: fixed;
  width: 50vw;
  max-width: 200px;
  height: 50vw;
  max-height: 200px;
  pointer-events: none
}

.rock {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: relative;
  transition: transform 0.1s;
}

.rock-shadow {
  width: 100%;
  height: 20%;
  border-radius: 50%;
  background-color: black;
  opacity: 0.5;
  z-index: 0;
  position: absolute;
  top: 65%;
  transition: transform 0.1s;
}

@media all and (max-width: $max-mobile) {
  .home-banner {
    max-height: calc((100vw * 308/410) + 20px); // 20px of margin
    margin-bottom: calc((100vw / 4));
  }
  .brain-rock {
    top: calc((100vw * 308/410)/2 + 20px);
  }
}
</style>