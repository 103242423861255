<template>
  <div class="left-container">
    <div  class="left-menu transition"
          :data-open="isOpen">
      <left-menu-item v-for="(menuItem, index) in menuData"
                      @close="close"
                      :key="index"
                      :menuItem="menuItem">
      </left-menu-item>
    </div>
    <div  class="cover clickable"
          @click="close"
          v-show="isOpen">
    </div>
  </div>
</template>

<script>
import creations from '@/components/home/new_creations.js'
import LeftMenuItem from '@/components/layout/LeftMenuItem'

export default {
  name: 'left-menu',
  components: { LeftMenuItem },
  props: ['isOpen'],
  data () {
    return {
      creations: creations,
      homeMenuData: {
        name: 'Home',
        path: '/',
        icon: '/icons/home-solid.svg',
        separation: true
      },
      engineerMenuData: {
        name: 'Software Engineer',
        path: '/software_engineer',
        icon: '/icons/magic-wand.svg'
      },
      authorAndComedianMenuData: {
        name: 'Author & Comedian',
        path: '/author_and_comedian',
        icon: '/icons/standup-mic.svg'
      }
    }
  },
  computed: {
    menuData () {
      let computedData = [this.homeMenuData, this.engineerMenuData]
      computedData.push(...creations.personal_projects)
      computedData.push(this.authorAndComedianMenuData)
      computedData.push(...creations.comedian)
      computedData.push(...creations.author)

      return computedData
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/sizes.scss';
@import '@/styles/colors.scss';

.left-container {
  position: fixed;
  z-index: 19;
}
.left-menu {
  position: fixed;
  padding: 50px 0 10px 0;
  width: 250px;
  height: 100vh;
  background-color: $primary;
  color: $primary-lighter;
  transform: translateX(-250px);
}
.left-menu[data-open] {
  transform: translateX(0);
}
.cover {
  position: static;
  height: 100vh;
  width: 100vw;
  background-color: $dark-transparent;
}
@media all and (min-width: $max-tablet) {
  .cover {
    display: none;
  }
}
</style>
