import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/pages/Home'
// import Webcomic from '@/pages/Webcomic'
// import Pro from '@/pages/Pro'
// import QrCodeGenerator from '@/pages/QrCodeGenerator'
// import ComedyClubs from '@/pages/ComedyClubs'
// import CaillouSurScene from '@/pages/CaillouSurScene'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/webcomic',
      name: 'Webcomic',
      component: () => import('@/pages/Webcomic')
    },
    {
      path: '/webcomic/:page',
      name: 'WebcomicPage',
      component: () => import('@/pages/Webcomic')
    },
    {
      path: '/pro',
      name: 'Pro',
      component: () => import('@/pages/Pro')
    },
    {
      path: '/qrcode',
      name: 'QrCode',
      component: () => import('@/pages/QrCodeGenerator')
    },
    {
      path: '/comedy_clubs',
      name: 'ComedyClubs',
      component: () => import('@/pages/ComedyClubs')
    },
    {
      path: '/sur_scene',
      name: 'CaillouSurScene',
      component: () => import('@/pages/CaillouSurScene')
    },
    {
      path: '/idle_please',
      name: 'IdlePlease',
      component: () => import('@/pages/IdlePlease')
    },
    {
      path: '/software_engineer',
      name: 'Engineer',
      component: () => import('@/pages/Engineer')
    },
    {
      path: '/author_and_comedian',
      name: 'AuthorAndComedian',
      component: () => import('@/pages/AuthorAndComedian')
    },
  ]
})
