<template>
  <div @click="close">
    <component  :is="menuItem.path ? 'router-link' : 'a'"
                :to="menuItem.path"
                :href="menuItem.link"
                :target="menuItem.link ? 'blank' : null"
                class="left-item clickable"
                :class="{ 'sub-item': menuItem.subItem }">
      <img  class="icon"
            :src="menuItem.icon" />
      {{menuItem.name || menuItem.title}}

      <img  class="outlink"
            src="/icons/arrow-up-right-from-square.svg"
            v-if="menuItem.link" />
    </component>
    <div  class="separation"
          :class="{ 'sub-item-separation': menuItem.subItem }"
          v-if="menuItem.separation">
    </div>
  </div>
</template>

<script>
export default {
  name: 'left-menu-item',
  props: ['menuItem'],
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.left-item {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  color: $primary-lighter;
}
.icon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.outlink {
  height: 12px;
  width: 12px;
  margin-left: 10px;
}
.separation {
  margin: 0 5px 5px 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid $primary-light;
}

.sub-item {
  margin-left: 15px;
  // border-left: 1px solid $primary-light;
}

.sub-item-separation {
  margin-top: 10px;
}
</style>
